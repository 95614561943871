import { IApiRes } from "../types/interfaces";

interface IUnwrapIsSuccess extends IApiRes {
    data: unknown;
}
export const isResponseSuccess = (res: IUnwrapIsSuccess) => {
    return res?.status === 1 && res?.data;
};

export const isSkipErrorMsg = (message_key: string) => {
    return message_key !== "CHANGE_PASSWORD.PASSWORD_CANNOT_BE_SAME_AS_PREVIOUS_THREE_TIMES_ERROR";
};
