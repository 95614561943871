import { useCallback, useEffect, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import { showCustomToast } from "../../../components/common/ToastNotify";
import { isResponseSuccess } from "../../../utils";
import { useLoginMutation } from "../services/authApiSlice";
import { postLogin } from "../services/authSlice";
import { IUserData } from "../types/interfaces";

const useLogin = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [codeInput, setCodeInput] = useState<string>();
    const [captchaCode, setCaptChCode] = useState<string>("");
    const [lockLogin, setLockLogin] = useState<boolean>(false);
    const [fetchLogin, { isLoading }] = useLoginMutation();
    const [remainingTime, setRemainingTime] = useState<number>(0);
    const LOCK_DURATION_MS = 900000; // 後端有鎖，這裡是為了清除localStorage
    // const LOCK_DURATION_MS = 180000; // 3 min 測試
    const schemaResolver = yupResolver(
        yup.object().shape({
            org_serial_number: yup.string().required(t("請輸入組織 ID")).max(40, t("ID 長度不得超過 40 字元")),
            password: yup.string().required(t("請輸入密碼")),
            account: yup.string().required(t("請輸入帳號")),
            code: yup.string().required(t("請輸入驗證碼")),
        })
    );

    const onSubmit = async (formData: IUserData) => {
        if (codeInput !== captchaCode) {
            showCustomToast.error("驗證碼錯誤");
            return;
        }

        const res = await fetchLogin(formData).unwrap();

        if (isResponseSuccess(res)) {
            console.log("login", res?.data?.amaze_access_token);
            dispatch(
                postLogin({
                    accessToken: res?.data?.access_token,
                    refreshToken: res?.data?.refresh_token,
                    amazeAccessToken: res?.data?.amaze_access_token,
                })
            );
            if (res?.data?.is_first_time_login) {
                navigate("/auth/init-password");
                return;
            }
            if (res?.message === "Password_Expired") {
                navigate("/auth/ask-reset-password");
                return;
            }
        }
        if (res?.message === "locked") {
            localStorage.setItem(`lock_time_${formData.account}`, new Date().toISOString());

            // 確保 15 分鐘後清除 localStorage
            const lockTimeout = setTimeout(() => {
                localStorage.removeItem(`lock_time_${formData.account}`);
                setLockLogin(false);
                setRemainingTime(0);

                clearTimeout(lockTimeout);
            }, LOCK_DURATION_MS);
            return;
        }
    };

    const isLockTimeValid = useCallback((accountId) => {
        const lockTime = localStorage.getItem(`lock_time_${accountId}`);

        if (!lockTime) {
            setLockLogin(false);
            return false;
        }
        const lockTimeDate = new Date(lockTime);
        const currentTime = new Date();
        const diffTime = Math.abs(currentTime.getTime() - lockTimeDate.getTime());
        const diffMinutes = Math.ceil(diffTime / (1000 * 60));

        if (diffMinutes > 15) {
            localStorage.removeItem(`lock_time_${accountId}`);
            setLockLogin(false);
            return false;
        } else {
            setRemainingTime(15 - diffMinutes);
            setLockLogin(true);
            return true;
        }
    }, []);

    return {
        schemaResolver,
        onSubmit,
        isLoading,
        setCaptChCode,
        setCodeInput,
        codeInput,
        lockLogin,
        isLockTimeValid,
        remainingTime,
    };
};
export default useLogin;
