import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const hasLowercase = (value: string): boolean => /[a-z]/.test(value);
const hasUppercase = (value: string): boolean => /[A-Z]/.test(value);
const hasDigit = (value: string): boolean => /\d/.test(value);
// const hasSpecialChar = (value: string): boolean =>
// /[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\-]/.test(value);
const extractSpecialChars = (value: string): string[] | null => value.match(/[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\-]/g);
export const isPasswordValid = (value: string) =>
    [hasLowercase(value), hasUppercase(value), hasDigit(value), extractSpecialChars(value)].filter(Boolean).length >= 3;

export const passwordSchema = yupResolver(
    yup.object().shape({
        oldPwd: yup.string().required("請輸入舊密碼"),
        newPwd: yup
            .string()
            .required("請輸入新密碼")
            .min(8, "密碼最少 8 字")
            .max(64, "必須小於 64 字")
            .notOneOf([yup.ref("oldPwd"), null], "不可與舊密碼相同")
            .test(
                "password_rules",
                "密碼必須包含以下四種條件中的至少三種：英文小寫, 英文大寫, 數字, 特殊字符",
                (value) => typeof value === "string" && isPasswordValid(value)
            ),
        confirmPwd: yup
            .string()
            .required("請確認與新密碼相符")
            .oneOf([yup.ref("newPwd"), null], "This value should be the same."),
    })
);

export const initPasswordSchema = yupResolver(
    yup.object().shape({
        old_password: yup.string().required("請輸入舊密碼"),
        password: yup
            .string()
            .required("請輸入新密碼")
            .min(8, "密碼最少 8 字")
            .max(64, "必須小於 64 字")
            .notOneOf([yup.ref("old_password"), null], "不可與舊密碼相同")
            .test(
                "password_rules",
                "密碼必須包含以下四種條件中的至少三種：英文小寫, 英文大寫, 數字, 特殊字符",
                (value) => typeof value === "string" && isPasswordValid(value)
            ),
        password2: yup
            .string()
            .required("請再次輸入新密碼")
            .oneOf([yup.ref("password"), null], "This value should be the same."),
    })
);
