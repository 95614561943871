import styles from "./LoginForm.module.scss";

import ClientCaptcha from "react-client-captcha";
import { useTranslation } from "react-i18next";

import { FormInput, VerticalForm } from "../../../components/form";
import Loader from "../../../components/Loader";
import useLogin from "../hooks/useLogin";
import { IUserData } from "../types/interfaces";

const LoginForm = () => {
    const { t } = useTranslation();
    const {
        schemaResolver,
        onSubmit,
        isLoading,
        setCaptChCode,
        setCodeInput,
        codeInput,
        lockLogin,
        isLockTimeValid,
        remainingTime,
    } = useLogin();

    return (
        <>
            {isLoading && <Loader />}
            <VerticalForm<IUserData>
                onSubmit={onSubmit}
                resolver={schemaResolver}
                hasBtn={true}
                submitBtnLabel={t("登入")}
                submitClasses={"w-100 text-center mt-3 "}
                isLockedCallback={isLockTimeValid}
            >
                <FormInput
                    type='text'
                    name='org_serial_number'
                    label={t("組織編號")}
                    placeholder={t("輸入組織ID")}
                    containerClass={"mb-3"}
                />
                <FormInput
                    type='text'
                    name='account'
                    label={t("帳號")}
                    placeholder={t("輸入帳號ID")}
                    containerClass={"mb-3"}
                />
                <FormInput
                    label={t("密碼")}
                    type='password'
                    name='password'
                    placeholder='輸入您的密碼'
                    containerClass={"mb-3"}
                />
                <FormInput
                    type='text'
                    name='code'
                    label={t("驗證碼")}
                    containerClass={styles.codeInput}
                    placeholder='輸入下方驗證碼'
                    value={codeInput || ""}
                    onChange={(e) => setCodeInput(e.target.value)}
                />
                <div className={styles.code}>
                    <ClientCaptcha
                        captchaCode={(code: string) => setCaptChCode(code)}
                        //retryIconSize={20}
                        height={37.8}
                        backgroundColor='#F7F7F7'
                        retry={false}
                        chars='abcdefghjkmnopqrstuvwxyzABCDEFGHJKLMNPQRSTUVWXYZ23456789'
                    />
                </div>
            </VerticalForm>
            {lockLogin && (
                <div className='text-center mt-3 text-danger'>
                    <p className='m-0'>{t("Login failed 3 times .")}</p>
                    <p className='mt-1 mb-0'>{t(`Account will be locked for ${remainingTime} minutes.`)}</p>
                </div>
            )}
        </>
    );
};
export default LoginForm;
